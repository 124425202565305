import { iconTheme, sectionStyle } from 'holded/lib/styles';

export const defaultThemeValues = {
  background: '',
  titleColor: '',
  titleSecondaryColor: '',
  subtitleColor: '',
  descriptionColor: '',
  icon: '',
};

export const FeatureTheme: FeatureThemeType = {
  feature: {
    background: {
      light: sectionStyle.background.default,
      soft: sectionStyle.background.secondary,
      dark: sectionStyle.background.dark,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    titleSecondaryColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.primary,
    },
    subtitleColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.light,
    },
    descriptionColor: {
      light: sectionStyle.textColor.secondary,
      soft: sectionStyle.textColor.secondary,
      dark: sectionStyle.textColor.blueSoft,
    },
    icon: {
      light: iconTheme.soft,
      soft: iconTheme.dark,
      dark: iconTheme.light,
    },
  },
  feature2: {
    background: {
      light: sectionStyle.background.default,
      soft: sectionStyle.background.secondary,
      dark: sectionStyle.background.dark,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    titleSecondaryColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    subtitleColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.light,
    },
    descriptionColor: {
      light: sectionStyle.textColor.secondary,
      soft: sectionStyle.textColor.secondary,
      dark: sectionStyle.textColor.blueSoft,
    },
    icon: {
      light: iconTheme.basic,
      soft: iconTheme.dark,
      dark: iconTheme.light,
    },
  },
  feature4: {
    background: {
      light: sectionStyle.background.default,
      soft: sectionStyle.background.secondary,
      dark: sectionStyle.background.dark,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    titleSecondaryColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    subtitleColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.light,
    },
    descriptionColor: {
      light: sectionStyle.textColor.secondary,
      soft: sectionStyle.textColor.secondary,
      dark: sectionStyle.textColor.blueSoft,
    },
    icon: {
      light: iconTheme.soft,
      soft: iconTheme.soft,
      dark: iconTheme.light,
    },
  },
  feature6: {
    background: {
      light: sectionStyle.background.default,
      soft: sectionStyle.background.secondary,
      dark: sectionStyle.background.dark,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    titleSecondaryColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.mint,
      dark: sectionStyle.textColor.blueSoft,
    },
    subtitleColor: {
      light: sectionStyle.textColor.mint,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.blueSoft,
    },
    descriptionColor: {
      light: sectionStyle.textColor.secondary,
      soft: sectionStyle.textColor.secondary,
      dark: sectionStyle.textColor.soft,
    },
    icon: {
      light: iconTheme.soft,
      soft: iconTheme.soft,
      dark: iconTheme.light,
    },
  },
  feature7: {
    background: {
      light: sectionStyle.background.default,
      soft: sectionStyle.background.secondary,
      dark: sectionStyle.background.dark,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    titleSecondaryColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.mint,
      dark: sectionStyle.textColor.blueSoft,
    },
    subtitleColor: {
      light: sectionStyle.textColor.mint,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.blueSoft,
    },
    descriptionColor: {
      light: sectionStyle.textColor.secondary,
      soft: sectionStyle.textColor.secondary,
      dark: sectionStyle.textColor.blueSoft,
    },
    icon: {
      light: iconTheme.soft,
      soft: iconTheme.dark,
      dark: iconTheme.light,
    },
  },
  feature9: {
    background: {
      blue: 'bg-blue-100',
      orange: 'bg-orange-100',
      green: 'bg-green-100',
    },
    textColor: {
      blue: 'text-blue-800',
      orange: 'text-orange-800',
      green: 'text-green-800',
    },
  },
};

export type FeatureThemeType = {
  feature: {
    background: {
      light: string;
      soft: string;
      dark: string;
    };
    titleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    titleSecondaryColor: {
      light: string;
      soft: string;
      dark: string;
    };
    subtitleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    descriptionColor: {
      light: string;
      soft: string;
      dark: string;
    };
    icon: {
      light: string;
      soft: string;
      dark: string;
    };
  };
  feature2: {
    background: {
      light: string;
      soft: string;
      dark: string;
    };
    titleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    titleSecondaryColor: {
      light: string;
      soft: string;
      dark: string;
    };
    subtitleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    descriptionColor: {
      light: string;
      soft: string;
      dark: string;
    };
    icon: {
      light: string;
      soft: string;
      dark: string;
    };
  };
  feature4: {
    background: {
      light: string;
      soft: string;
      dark: string;
    };
    titleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    titleSecondaryColor: {
      light: string;
      soft: string;
      dark: string;
    };
    subtitleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    descriptionColor: {
      light: string;
      soft: string;
      dark: string;
    };
    icon: {
      light: string;
      soft: string;
      dark: string;
    };
  };
  feature6: {
    background: {
      light: string;
      soft: string;
      dark: string;
    };
    titleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    titleSecondaryColor: {
      light: string;
      soft: string;
      dark: string;
    };
    subtitleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    descriptionColor: {
      light: string;
      soft: string;
      dark: string;
    };
    icon: {
      light: string;
      soft: string;
      dark: string;
    };
  };
  feature7: {
    background: {
      light: string;
      soft: string;
      dark: string;
    };
    titleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    titleSecondaryColor: {
      light: string;
      soft: string;
      dark: string;
    };
    subtitleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    descriptionColor: {
      light: string;
      soft: string;
      dark: string;
    };
    icon: {
      light: string;
      soft: string;
      dark: string;
    };
  };
  feature9: {
    background: {
      blue: string;
      orange: string;
      green: string;
    };
    textColor: {
      blue: string;
      orange: string;
      green: string;
    };
  };
};
